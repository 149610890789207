<template>
  <v-container
    :class="className"
    @click="onClickButton"
    style="cursor:pointer;"
  >
    <v-row>
      <v-col
        v-if="icon"
        cols="3"
        sm="3"
        md="12"
        class="d-flex justify-center align-center"
      >
        <img
          v-if="icon"
          width="85"
          height="85"
          :src="getIconUrl(icon)"
          alt=""
          class="btn-checker-iamge"
        />
        <v-icon v-else size="85">{{ icon }}</v-icon>
      </v-col>
      <v-col cols="9" sm="9" md="12" v-if="icon" class="buttonchecker-icon">
        <p :class="classTitle">{{ title }}</p>
        <p :class="classSubTitle" class="buttonchecker-sub-title-padding">
          {{ subTitle }}
        </p>
        <v-icon v-if="isClicked" class="btn--iconcheck white--text"
          >mdi-check</v-icon
        >
      </v-col>
      <v-col cols="12" sm="12" md="12" v-if="!icon">
        <p :class="classTitle">{{ title }}</p>
        <p :class="classSubTitle">{{ subTitle }}</p>
        <v-icon v-if="isClicked" class="btn--iconcheck white--text"
          >mdi-check</v-icon
        >
      </v-col>
    </v-row>
  </v-container>
  <!-- <v-container
    :class="className"
    @click="onClickButton"
    style="cursor:pointer;"
  >
    <v-row>
      <v-col
        v-if="icon"
        cols="3"
        sm="3"
        md="12"
        class="d-flex justify-center align-center"
      >
        <img
          v-if="icon"
          width="85"
          height="85"
          :src="getIconUrl(icon)"
          alt=""
          class="btn-checker-iamge"
        />
        <v-icon v-else size="85">{{ icon }}</v-icon>
      </v-col>
      <v-col cols="9" sm="9" md="12" v-if="icon" class="buttonchecker-icon">
        <p :class="classTitle">{{ title }}</p>
        <p :class="classSubTitle">{{ subTitle }}</p>
        <v-icon v-if="isClicked" class="btn--iconcheck white--text"
          >mdi-check</v-icon
        >
      </v-col>
      <v-col cols="12" sm="12" md="12" v-if="!icon">
        <p :class="classTitle">{{ title }}</p>
        <p :class="classSubTitle">{{ subTitle }}</p>
        <v-icon v-if="isClicked" class="btn--iconcheck white--text"
          >mdi-check</v-icon
        >
      </v-col>
    </v-row>
  </v-container> -->
  <!-- <v-container :class="className" @click="onClickButton" style="cursor:pointer;">
    <img v-if="icon" width="85" height="85" :src="getIconUrl(icon)" alt="" />
    <v-icon v-else size="85">{{ icon }}</v-icon>

    <p :class="classTitle">{{ title }}</p>
    <p :class="classSubTitle">{{ subTitle }}</p>
    <v-icon v-if="isClicked" class="btn--iconcheck white--text">mdi-check</v-icon>
  </v-container> -->
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    isClicked: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: "text-center"
    },
    classTitle: {
      type: String,
      // default: "text-center mb-0 text--bold--20"
      default: "mb-0 buttonchecker-class-title"
    },
    classSubTitle: {
      type: String,
      // default: "text-center mb-0 text--regular--14"
      default: "mb-0 text--regular--14"
    }
  },
  methods: {
    onClickButton() {
      this.$emit("clicked", true);
    },
    getIconUrl(iconName) {
      if (iconName) {
        return require(`../assets/icons/${iconName}`);
      }
      return "";
    }
  }
};
</script>

<style scoped>
.btn--iconcheck {
  position: absolute;
  top: -12px;
  right: -8px;
  background-color: var(--v-primary);
  border-radius: 14px;
}

.btnchecker--tick {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 100%;
}
.buttonchecker-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.buttonchecker-class-title {
  font-weight: 700;
  font-size: 20px;
}
.btn-checker-iamge {
  max-width: 100%;
  width: 95.21px;
  object-fit: cover;
}
/* Padding Section */
.buttonchecker-sub-title-padding {
  padding-top: 10px;
}
</style>
