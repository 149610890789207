<template>
  <v-container>
    <v-row class="pt-0">
      <v-col
        class="pt-0 pb-0"
        xs="12"
        sm="10"
        offset-sm="2"
        md="10"
        offset-md="2"
      >
        <v-container class="pt-0 pb-0">
          <v-col class="rent--space-criteria-container-padding">
            <p class="text--regular--14 mb-0 rent--space-criteria-divider">
              ยินดีต้อนรับสู่การขอใช้พื้นที่,
              กรุณาเลือกตัวเลือกที่ระบุไว้ก่อนเริ่มต้น
            </p>
            <div class="padding-top-18-dot-2">
              <v-divider></v-divider>
            </div>
            <p
              class="text--bold--22 mb-0 rent-space-criteria-specify-are-header-padding"
            >
              ระบุประเภทของผู้ขอใช้พื้นที่
            </p>
            <p class="text--regular--14 mb-0 padding-top-4">
              ประเภทของผู้ขอใช้จะมีผลกับเอกสารที่ต้องใช้เพื่อประกอบการขอใช้พื้นที่
            </p>

            <div
              v-if="rentSpaceCriteriaData !== null"
              class="mt-0 rent-space-criteria-specify-are-list-padding"
            >
              <v-row justify="start">
                <div
                  v-for="(value, index) in rentSpaceCriteriaData"
                  :key="value.id"
                  :class="[
                    value.checked
                      ? 'rent--space-criteria--btn--tick text--regular--16 rent-space-criteria-are-margin'
                      : 'rent--space-criteria--btn--untick text--regular--16 rent-space-criteria-are-margin'
                  ]"
                >
                  <ButtonChecker
                    class="text--regular--16"
                    icon=""
                    :subTitle="value.applicant_code == 'INTERNAL' ? '(เฉพาะเจ้าหน้าที่ สศร.)' : ''"
                    :title="value.applicant_name"
                    @clicked="onClickApplicanType(value.applicant_code, index)"
                    :isClicked="value.checked"
                    classTitle="text-center mb-0 text--regular--16"
                  ></ButtonChecker>
                </div>
                <v-spacer></v-spacer>
              </v-row>
            </div>

            <div class="hidden-sm-and-down margin-top-10">
              <ul class="rent--space-criteria-organization padding-top-28">
                <li>
                  ประเภทหน่วยงานเอกชน ได้แก่ สถานศึกษาเอกชน / บริษัท
                  ห้างหุ้นส่วนจำกัด ห้างหุ้นส่วนสามัญ / สมาคม มูลนิธิ / อื่น ๆ
                </li>
                <li>
                  ประเภทหน่วยงานรัฐ/ราชการ ได้แก่ หน่วยงานของรัฐ ส่วนราชการ
                  รัฐวิสาหกิจ องค์การมหาชน / สถานศึกษาของรัฐ
                </li>
              </ul>
            </div>

            <!-- <div class="pt-md-8 mt-12"> -->
            <div class="rent-space-criteria-select-are-header-padding">
              <p class="text--bold--22 mb-0">เลือกประเภทการขอใช้พื้นที่</p>
              <p class="text--regular--14 mb-0 margin-top-3">
                การเลือกประเภทเพื่อรองรับกับผู้ขอใช้พื้นที่ที่มีพื้นที่ที่ต้องการอยู่แล้ว
                หรือผู้ที่มีวันที่ที่ต้องการจะจัดงานขึ้น
              </p>
            </div>

            <!-- <v-row
              class="ma-0 rent-space-criteria-select-are-list-padding"
              justify="start"
            > -->
            <v-row
              class="rent-space-criteria-select-are-list-padding mt-0"
              justify="start"
            >
              <div
                :class="[
                  isDateType
                    ? 'rent--space-criteria--btn--tick--big rent-space-criteria-select-are-item-margin'
                    : 'rent--space-criteria--btn--untick--big rent-space-criteria-select-are-item-margin'
                ]"
              >
                <ButtonChecker
                  icon="criteria-calendar.svg"
                  title="ต้องการเลือกวันที่ก่อน"
                  subTitle="สำหรับผู้ที่ต้องการยึดจากวันที่เป็นหลักก่อนพื้นที่และไม่มีพื้นที่ที่ต้องจำกัด"
                  @clicked="onClickDateType"
                  :isClicked="isDateType"
                ></ButtonChecker>
              </div>
              <div
                :class="[
                  isAreaType
                    ? 'rent--space-criteria--btn--tick--big rent-space-criteria-select-are-item-margin'
                    : 'rent--space-criteria--btn--untick--big rent-space-criteria-select-are-item-margin'
                ]"
              >
                <ButtonChecker
                  icon="criteria-area.svg"
                  title="ต้องการเลือกพื้นที่ก่อน"
                  subTitle="สำหรับผู้ที่ต้องการดูจากพื้นที่เป็นหลักและยังไม่มีวันที่จัดงานแน่นอน"
                  @clicked="onClickAreaType"
                  :isClicked="isAreaType"
                ></ButtonChecker>
              </div>
              <v-spacer></v-spacer>
            </v-row>
            <v-row justify="start">
              <div class="text-center rent-space-criteria-space-container">
                <p class="text--regular--16 text-center">
                  *วันที่ประสงค์จะขอใช้พื้นที่ จำเป็นจะต้อง<span
                    class="text--regular--16 rent-space-criteria-space-title"
                    >ไม่น้อยกว่า 90 วัน นับตั้งแต่วันขออนุญาต</span
                  >
                </p>
              </div>
            </v-row>

            <v-row justify="start">
              <div class="text-center rent--space-criteria--btn-start">
                <v-btn
                  rounded
                  color="primary"
                  dark
                  elevation="0"
                  @click="submitRentSpaceCriteria"
                  class="rent--space-criteria--btn--start"
                >
                  <span class="rent--space-criteria--btn--start-text"
                    >เริ่มต้น</span
                  >
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog
      id="modal-template"
      v-model="popupDialog"
      max-width="780"
      :retain-focus="false"
      persistent
    >
      <v-card class="pa-10 confirmation-popup-card">
        <v-card-title class="confirmation-popup-title">
          <p class="mb-0 popup-comfirm-delete-text">
            คุณต้องการขอใช้พื้นที่ ในระยะเวลา 90 วันนี้ใช่หรือไม่ ?
          </p>
        </v-card-title>
        <v-card-actions class="popup-comfirm-delete">
          <v-btn
            elevation="0"
            class="popup-comfirm-delete-button mb-2"
            outlined
            rounded
            color="black"
            @click="gotoGoogleForm()"
            width="200"
          >
            <p class="mb-0 black--text">
              ใช่
            </p>
          </v-btn>
          <v-btn
            elevation="0"
            outlined
            rounded
            class="popup-comfirm-delete-button mb-2"
            color="black"
            @click="closeDialog()"
            width="200"
          >
            <p class="mb-0 black--text ">
              ไม่ใช่
            </p>
          </v-btn>
        </v-card-actions>
        <v-btn
          :ripple="false"
          fab
          text
          small
          color="grey darken-2"
          @click="closeDialog()"
          class="confirmation--closeicon"
        >
          <v-icon large :ripple="false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
  <!-- <v-container>
    <v-row>
      <v-col xs="12" sm="10" offset-sm="2" md="10" offset-md="2">
        <v-container>
          <v-col>
            <p class="text--regular--14">
              ยินดีต้อนรับสู่การขอใช้พื้นที่,
              กรุณาเลือกตัวเลือกที่ระบุไว้ก่อนเริ่มต้น
            </p>
            <v-divider></v-divider>
            <p class="pt-4 text--bold--22 mt-4 mb-1">
              ระบุประเภทของผู้ขอใช้พื้นที่
            </p>
            <p class="text--regular--14 mb-5">
              ประเภทของผู้ขอใช้จะมีผลกับเอกสารที่ต้องใช้เพื่อประกอบการขอใช้พื้นที่
            </p>

            <div v-if="rentSpaceCriteriaData !== null">
              <v-row class="pt-md-4" justify="start">
                <div
                  v-for="(value, index) in rentSpaceCriteriaData"
                  :key="value.id"
                  :class="[
                    value.checked
                      ? 'rent--space-criteria--btn--tick text--regular--16 ma-2'
                      : 'rent--space-criteria--btn--untick text--regular--16 ma-2'
                  ]"
                >
                  <ButtonChecker
                    class="text--regular--16"
                    icon=""
                    subTitle=""
                    :title="value.applicant_name"
                    @clicked="onClickApplicanType(value.applicant_code, index)"
                    :isClicked="value.checked"
                    classTitle="text-center mb-0 text--regular--16"
                  ></ButtonChecker>
                </div>
                <v-spacer></v-spacer>
              </v-row>
            </div>

            <div class="mt-8 hidden-sm-and-down">
              <ul class="rent--space-criteria-organization">
                <li>
                  ประเภทหน่วยงานเอกชน ได้แก่ สถานศึกษาเอกชน / บริษัท
                  ห้างหุ้นส่วนจำกัด ห้างหุ้นส่วนสามัญ / สมาคม มูลนิธิ / อื่น ๆ
                </li>
                <li>
                  ประเภทหน่วยงานรัฐ/ราชการ ได้แก่ หน่วยงานของรัฐ ส่วนราชการ
                  รัฐวิสาหกิจ องค์การมหาชน / สถานศึกษาของรัฐ
                </li>
              </ul>
            </div>

            <div class="mt-12">
              <p class="text--bold--22 mt-4 mb-1">เลือกประเภทการขอใช้พื้นที่</p>
              <p class="text--regular--14 mb-5">
                การเลือกประเภทเพื่อรองรับกับผู้ขอใช้พื้นที่ที่มีพื้นที่ที่ต้องการอยู่แล้ว
                หรือผู้ที่มีวันที่ที่ต้องการจะจัดงานขึ้น
              </p>
            </div>

            <v-row class="pt-md-4" justify="start">
              <div
                :class="[
                  isDateType
                    ? 'rent--space-criteria--btn--tick--big ma-2'
                    : 'rent--space-criteria--btn--untick--big ma-2'
                ]"
              >
                <ButtonChecker
                  icon="criteria-calendar.svg"
                  title="ต้องการเลือกวันที่ก่อน"
                  subTitle="สำหรับผู้ที่ต้องการยึดจากวันที่เป็นหลักก่อนพื้นที่และไม่มีพื้นที่ที่ต้องจำกัด"
                  @clicked="onClickDateType"
                  :isClicked="isDateType"
                ></ButtonChecker>
              </div>
              <div
                :class="[
                  isAreaType
                    ? 'rent--space-criteria--btn--tick--big ma-2'
                    : 'rent--space-criteria--btn--untick--big ma-2'
                ]"
              >
                <ButtonChecker
                  icon="criteria-area.svg"
                  title="ต้องการเลือกพื้นที่ก่อน"
                  subTitle="สำหรับผู้ที่ต้องการดูจากพื้นที่เป็นหลักและยังไม่มีวันที่จัดงานแน่นอน"
                  @clicked="onClickAreaType"
                  :isClicked="isAreaType"
                ></ButtonChecker>
              </div>
              <v-spacer></v-spacer>
            </v-row>
            <v-row justify="start">
              <div
                class="text-center mt-10 mb-15 rent--space-criteria--btn-start ma-2"
              >
                <v-btn
                  elevation="0"
                  medium
                  rounded
                  color="primary"
                  @click="submitRentSpaceCriteria"
                  class="pl-16 pr-16"
                >
                  <span class="black--text rent--space-criteria--btn--start"
                    >เริ่มต้น</span
                  ></v-btn
                >
              </div>
            </v-row>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
  </v-container> -->
</template>
<script>
import { mapState, mapActions } from "vuex";
import ButtonChecker from "@/components/ButtonChecker.vue";
import _get from "lodash/get";

export default {
  computed: {
    ...mapState({
      rentSpaceCriteriaData: state => {
        return state.rentspacecriteria.rentSpaceCriteriaData;
      }
    })
  },
  name: "home",
  components: {
    ButtonChecker
  },
  created() {
    const filters = {
      order: "id asc",
      include: [
        {
          relation: "organizationsTypes",
          scope: {
            order: "id asc",

            include: [
              {
                relation: "requireDocs",
                scope: {
                  order: "id asc"
                }
              }
            ]
          }
        }
      ]
    };
    this.initPage(filters);
    this.$store.dispatch("datespecify/resetStateData");
    this.$store.dispatch("areaspecify/resetStateData");
    this.$store.dispatch("booking/resetStateData");
  },
  data: function() {
    return {
      popupDialog: true,
      isLoginForm: true,
      isDateType: true,
      isAreaType: false,
      isGuestType: true,
      isGovernmentAgencyType: false,
      isPrivateAgencyType: false,
      isInternalType: false,
      applicantType: [
        {
          id: 1,
          title: "ประชาชน/บุคคลทั่วไป",
          isTick: true
        },
        {
          id: 2,
          title: "หน่วยงานเอกชน",
          isTick: false
        },
        {
          id: 3,
          title: "หน่วยงานรัฐ/ราชการ",
          isTick: true
        },
        {
          id: 4,
          title: "หน่วยงานภายใน",
          isTick: false
        }
      ],
      rentSpaceCriteriaList: this.rentSpaceCriteriaData
    };
  },
  mounted() {
    // Start : Sasiprapa B.: 2021-04-25
    this.setBackgroundMain();
    // End : Sasiprapa B.: 2021-04-25
  },
  methods: {
    gotoGoogleForm() {
      window.location.href = "https://www.google.com";
    },
    closeDialog() {
      this.popupDialog = false;
    },
    ...mapActions("applicantdetail", ["setRequireDoc"]),
    ...mapActions("rentspacecriteria", [
      "initPage",
      "rentSpaceCriteriaSelected",
      "updateCheckedRentSpaceCriteria"
    ]),
    // Start : Sasiprapa B.: 2021-04-25
    ...mapActions("app", ["setBackgroundFormStepperScreen"]),
    // End : Sasiprapa B.: 2021-04-25
    onHandleForm(event) {
      this.isLoginForm = event === "login";
    },
    onClickApplicanType(type, index) {
      this.isPrivateAgencyType = true;
      let newValue = this.rentSpaceCriteriaData.map(o => {
        return {
          ...o,
          checked: false
        };
      });
      newValue[index].checked = true;
      this.updateCheckedRentSpaceCriteria(newValue);

      switch (type) {
        case "GUEST":
          if (!this.isGuestType) {
            this.isGuestType = true;
          }
          if (this.isGuestType) {
            this.isGovernmentAgencyType = false;
            this.isPrivateAgencyType = false;
            this.isInternalType = false;
          }
          break;
        case "GOVERNMENTAGENCY":
          if (!this.isGovernmentAgencyType) {
            this.isGovernmentAgencyType = true;
          }
          if (this.isGovernmentAgencyType) {
            this.isGuestType = false;
            this.isPrivateAgencyType = false;
            this.isInternalType = false;
          }
          break;
        case "PRIVATEAGENCY":
          if (!this.isPrivateAgencyType) {
            this.isPrivateAgencyType = true;
          }
          if (this.isPrivateAgencyType) {
            this.isGovernmentAgencyType = false;
            this.isGuestType = false;
            this.isInternalType = false;
          }
          break;
        case "INTERNAL":
          if (!this.isInternalType) {
            this.isInternalType = true;
          }
          if (this.isInternalType) {
            this.isGuestType = false;
            this.isGovernmentAgencyType = false;
            this.isPrivateAgencyType = false;
          }
          break;

        default:
          break;
      }
    },

    onClickDateType() {
      if (!this.isDateType) {
        this.isDateType = true;
      }
      if (this.isDateType) {
        this.isAreaType = false;
      }
    },
    onClickAreaType() {
      if (!this.isAreaType) {
        this.isAreaType = true;
      }
      if (this.isAreaType) {
        this.isDateType = false;
      }
    },
    submitRentSpaceCriteria() {
      let applicantSelected = this.rentSpaceCriteriaData.filter(
        o => o.checked === true
      );
      let rentSpaceCriteria = {
        applicantType: this.isGuestType
          ? "GUEST"
          : this.isGovernmentAgencyType
          ? "GOVERNMENTAGENCY"
          : this.isPrivateAgencyType
          ? "PRIVATEAGENCY"
          : this.isInternalType
          ? "INTERNAL"
          : "",
        requestType: this.isDateType ? "DATE" : this.isAreaType ? "AREA" : ""
      };
      if (applicantSelected.length > 0) {
        rentSpaceCriteria.applicantSelected = applicantSelected[0];
      }
      if (rentSpaceCriteria.applicantType === "GUEST") {
        let requireDoc = _get(
          applicantSelected,
          "[0].organizationsTypes[0].requireDocs",
          null
        );
        this.setRequireDoc(requireDoc);
      } else {
        this.setRequireDoc(null);
      }

      this.rentSpaceCriteriaSelected(rentSpaceCriteria);
    },
    // Start : Sasiprapa B.: 2021-04-25
    setBackgroundMain() {
      // set 0 : clear stepper
      this.setBackgroundFormStepperScreen(0);
    }
    // End : Sasiprapa B.: 2021-04-25
  }
};
</script>

<style scoped>
.rent--space-criteria--btn--tick {
  position: relative;
  width: 201px;
  height: 80px;
  border-style: solid;
  border-color: var(--v-primary);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rent--space-criteria--btn--untick {
  position: relative;
  width: 201px;
  height: 80px;
  border-style: solid;
  border-width: thin;
  border-color: #c7c7c7;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rent--space-criteria--btn--tick--big {
  position: relative;
  width: 327px;
  height: 279px;
  border-style: solid;
  border-color: var(--v-primary);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rent--space-criteria--btn--untick--big {
  position: relative;
  width: 327px;
  height: 279px;
  border-style: solid;
  border-width: thin;
  border-color: #c7c7c7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rent--space-criteria--btn--start {
  font-weight: 500;
  font-size: 14px;
  border-radius: 23px;
  height: 45px !important;
  width: 207px !important;
}
.rent--space-criteria--btn--start-text {
  width: 44px;
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #282828;
}

.rent-space-criteria-space-title {
  text-decoration: underline;
  /* cursor: pointer; */
}

ul.rent--space-criteria-organization {
  list-style-type: disc;
}

/* Padding Section */
.rent-space-criteria-specify-are-header-padding {
  padding-top: 22.8px;
}

.rent-space-criteria-specify-are-list-padding {
  padding-top: 35px;
}

.rent-space-criteria-select-are-header-padding {
  padding-top: 44px;
}

.rent-space-criteria-select-are-list-padding {
  padding-top: 41px;
}

.rent-space-criteria-select-are-item-margin {
  margin-right: 10px;
  margin-left: 12px;
}

.rent-space-criteria-are-margin {
  margin-right: 13px;
  margin-left: 12px;
}

.rent--space-criteria--btn-start {
  width: 698px;
  /* padding: 60px 0px 101px; */
  padding: 20px 0px 101px;
}
.rent-space-criteria-space-container {
  width: 698px;
  padding-top: 25px;
}

.rent--space-criteria-container-padding {
  padding-top: 64px;
}

/* mobile */
@media (max-width: 959px) {
  .rent--space-criteria-container-padding {
    padding-top: 35px;
  }
}

/* Pop up */
.confirmation-popup-card {
  box-shadow: 0px 0px 15px #496c7733 !important;
  border-radius: 10px;
}
.confirmation-popup-title {
  display: flex;
  justify-content: center;
}
.popup-comfirm-delete-text {
  font-weight: 700;
  font-size: 21px;
}
.popup-comfirm-delete-text {
  font-weight: 700;
  font-size: 21px;
}

.popup-comfirm-delete-button {
  font-weight: 500;
  font-size: 14px;
}

.popup-comfirm-delete {
  display: flex;
  justify-content: center;
}
.confirmation--closeicon {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>
